import React from 'react';

import { IPayInvoiceResources, PayInvoiceComponent } from '@msdyn365-commerce-modules/invoice';
import { IComponent, IComponentProps, ICoreContext, IDateFormatOptions, msdyn365Commerce } from '@msdyn365-commerce/core';

import { InvoiceDetailsModel } from '../helpers/invoice-data';

export interface IInvoiceDetailsHeaderData {
    invoiceDetails: InvoiceDetailsModel;
}

export interface IInvoiceDetailsHeaderResources extends IPayInvoiceResources {
    invoiceOrderItem: string;
    invoiceOrderItems: string;
    invoiceIdLabel: string;
    invoicePlacedByLabel: string;
    invoiceAmountDueText: string;
    invoicePaymentDueDateText: string;
}

export interface IInvoiceDetailsHeaderProps extends IComponentProps<IInvoiceDetailsHeaderData> {
    context: ICoreContext;
    className: string;
    resources: IInvoiceDetailsHeaderResources;
    onError?(): void;
}

export interface IInvoiceDetailsHeaderComponent extends IComponent<IInvoiceDetailsHeaderProps> {
}

const formatShortDate = (context: ICoreContext, date: Date | undefined) => {
    if (date === undefined) {
        return '';
    }
    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
    return context.cultureFormatter.formatDate(date, dateOptions);
};

const formatLongDate = (context: ICoreContext, date: Date | undefined) => {
    if (date === undefined) {
        return '';
    }
    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric'};
    return context.cultureFormatter.formatDate(date, dateOptions);
};

const formatAmount = (context: ICoreContext, amount: number | undefined, currencyCode: string | undefined) => {
    if (amount === undefined) {
        return '';
    }
    return context.cultureFormatter.formatCurrency(amount, currencyCode);
};

const InvoiceDetailsHeader: React.FC<IInvoiceDetailsHeaderProps> = (props: IInvoiceDetailsHeaderProps): JSX.Element => {
    const { className, data: { invoiceDetails }, resources, onError } = props;

    const invoiceNumberText = resources.invoiceIdLabel.replace('{0}', invoiceDetails.id);

    const invoiceOrderItemsText = invoiceDetails.lines.length === 1 ?
        resources.invoiceOrderItem :
        resources.invoiceOrderItems.replace('{0}', invoiceDetails.lines.length.toString());

    const invoiceInfoContainerClassName = `${className}__invoice-info-container`;
    const orderInfoContainerClassName = `${invoiceInfoContainerClassName}__order-info-container`;
    const orderInfoClassName = `${orderInfoContainerClassName}__order-info`;
    const orderMainInfoClassName = `${orderInfoClassName}__main-info`;
    const orderDetailsClassName = `${orderInfoClassName}__details`;`${className}__actions`;
    const actionsClassName = `${className}__actions`;
    const amountSummaryClassName = `${invoiceInfoContainerClassName}__amount-summary`;
    const orderDate = formatShortDate(props.context, invoiceDetails.invoiceDate);

    const dueDate = formatLongDate(props.context, invoiceDetails.dueDate);
    const dueDateText = resources.invoicePaymentDueDateText.replace('{0}', dueDate);

    return (
        <div className={className}>
           <div className={`${className}__invoice-id`}>{invoiceNumberText}</div>
           <div className={invoiceInfoContainerClassName}>
                <div className={orderInfoContainerClassName}>
                    <div className={orderInfoClassName}>
                        <div className={`${orderMainInfoClassName}`}>
                                <div className={`${orderMainInfoClassName}__order-placed-by`}>{resources.invoicePlacedByLabel}<b>{invoiceDetails.customerName}</b></div>
                        </div>
                        <div className={orderDetailsClassName}>
                            <div className={`${orderDetailsClassName}__order-date`}>{orderDate}</div>
                            <div className={`${orderDetailsClassName}__order-items-count`}>{invoiceOrderItemsText}</div>
                        </div>
                    </div>
                </div>
                <div className={amountSummaryClassName}>
                    <div className={`${amountSummaryClassName}__amount-due-text`}>{resources.invoiceAmountDueText}</div>
                    <div className={`${amountSummaryClassName}__amount-due-amount`}>{formatAmount(props.context, invoiceDetails.amountDue, invoiceDetails.currencyCode)}</div>
                    <div className={`${amountSummaryClassName}__amount-due-date`}>{dueDateText}</div>
                </div>
           </div>
            <div className={actionsClassName}>
                <PayInvoiceComponent
                    context={props.context}
                    className={`${actionsClassName}__pay-invoice`}
                    resources={props.resources}
                    id={`${actionsClassName}__${invoiceDetails.id}`}
                    typeName={props.typeName}
                    data={{invoice: invoiceDetails}}
                    onError={onError}
                />
            </div>
        </div>
    );
};

// @ts-ignore
export const InvoiceDetailsHeaderComponent: React.FunctionComponent<IInvoiceDetailsHeaderProps> = msdyn365Commerce.createComponent<IInvoiceDetailsHeaderComponent>(
    'InvoiceDetailsHeaderComponent',
    { component: InvoiceDetailsHeader }
);