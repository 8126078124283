import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import React from 'react';

export interface IInvoiceErrorProps extends IComponentProps {
    messageHeader?: string;
    errorMessage?: string;
    otherError?: string;
    showError: boolean;
    payInvoiceErrorModalOpen: boolean;

    errorDialogHeader: string;
    errorDialogBodyErrorTitle: string;
    errorDialogBodyErrorDescription: string;
    errorDialogFooterGoToCartButtonText: string;
    errorDialogFooterCancelButtonText: string;
    cartUrl: string;

    setPayInvoiceErrorModalClose(): void;
}

export interface IInvoiceErrorComponent extends IComponent<IInvoiceErrorProps> {
}

export const InvoiceError: React.FC<IInvoiceErrorProps> = ({  messageHeader, errorMessage, otherError, showError, payInvoiceErrorModalOpen, errorDialogHeader, errorDialogBodyErrorTitle, errorDialogBodyErrorDescription,
    errorDialogFooterGoToCartButtonText, errorDialogFooterCancelButtonText, cartUrl, setPayInvoiceErrorModalClose }) => {
    const errorMessages: (string | undefined)[] = [];

    if (otherError) {
        errorMessages.push(otherError);
    } else {
        errorMessages.push(errorMessage);
    }

    const className = 'msc-pay-invoice-error-dialog';

    return (
        <>
            <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={messageHeader} >
                <div className='msc-alert__header' aria-hidden='true'>
                    <span className='msi-exclamation-triangle' />
                    <span>{messageHeader}</span>
                </div>
                {errorMessages.map((message, index) => {
                    return (
                        <div key={index} className='msc-alert__line'>{message}</div>
                    );
                })}
            </Alert>
            <Modal isOpen={payInvoiceErrorModalOpen} className={className} toggle={setPayInvoiceErrorModalClose}>
                <ModalHeader className={`${className}__header`} toggle={setPayInvoiceErrorModalClose}>
                    <span>{errorDialogHeader}</span>
                </ModalHeader>
                <ModalBody className={`${className}__body`}>
                    <div className={`${className}__content`}>
                        <div className={`${className}__error-title`}>{errorDialogBodyErrorTitle}</div>
                        <div className={`${className}__error-description`}>{errorDialogBodyErrorDescription}</div>
                    </div>
                </ModalBody>
                <ModalFooter className={`${className}__footer`}>
                    <Button
                        className={`${className}__review-shopping-bag-button`}
                        title={errorDialogFooterGoToCartButtonText}
                        href={cartUrl}
                    >
                        {errorDialogFooterGoToCartButtonText}
                    </Button>
                    <Button
                        className={`${className}__cancel-button`}
                        title={errorDialogFooterCancelButtonText}
                        onClick={setPayInvoiceErrorModalClose}
                    >
                        {errorDialogFooterCancelButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

// @ts-ignore
export const InvoiceErrorComponent: React.FunctionComponent<IInvoiceErrorProps> = msdyn365Commerce.createComponent<IInvoiceErrorComponent>(
    'InvoiceError',
    { component: InvoiceError }
);