import { InvoicePaidStatus } from '@msdyn365-commerce/retail-proxy';

export interface IInvoiceStatusResources {
    invoiceStatusNone: string;
    invoiceStatusUnpaid: string;
    invoiceStatusPartiallyPaid: string;
    invoiceStatusPaid: string;
}

export const getInvoiceStatusText = (resources: IInvoiceStatusResources, invoiceStatus: InvoicePaidStatus | undefined) => {
    switch (invoiceStatus) {
        // tslint:disable-next-line:prefer-type-cast
        case 0 as InvoicePaidStatus.None:
            return resources.invoiceStatusNone;
        // tslint:disable-next-line:prefer-type-cast
        case 1 as InvoicePaidStatus.Unpaid:
            return resources.invoiceStatusUnpaid;
        // tslint:disable-next-line:prefer-type-cast
        case 2 as InvoicePaidStatus.PartiallyPaid:
            return resources.invoiceStatusPartiallyPaid;
        // tslint:disable-next-line:prefer-type-cast
        case 3 as InvoicePaidStatus.Paid:
            return resources.invoiceStatusPaid;
        default:
            return '';
    }
};