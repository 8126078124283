import { InvoicePaidStatus, OrderInvoice } from '@msdyn365-commerce/retail-proxy';

export interface IPayableInvoice {
    readonly id: string;
    readonly canBePaid: boolean;
}

/**
 * Represents invoice data model.
 */
export class InvoiceModel implements IPayableInvoice {
    public id: string;
    public invoiceDate?: Date;
    public customerAccountNumber?: string;
    public customerName?: string;
    public amount?: number;
    public dueDate?: Date;
    public amountDue?: number;
    public status?: InvoicePaidStatus;
    public currencyCode?: string;

    public isSelected: boolean;

    public constructor(invoice: OrderInvoice) {
        this.id = invoice.Id || '';
        this.invoiceDate = invoice.InvoiceDate ? new Date(invoice.InvoiceDate) : undefined;
        this.customerAccountNumber = invoice.CustomerAccountNumber;
        this.customerName = invoice.CustomerName;
        this.amount = invoice.TotalAmount;
        this.dueDate = invoice.InvoiceDueDate ? new Date(invoice.InvoiceDueDate) : undefined;
        this.amountDue = invoice.AmountBalance;
        this.status = invoice.InvoicePaidStatusValue;
        this.currencyCode = invoice.CurrencyCode;

        this.isSelected = false;
    }

    public get canBePaid(): boolean {
        // tslint:disable-next-line:prefer-type-cast
        return (this.status !== 3 as InvoicePaidStatus.Paid);
    }
}