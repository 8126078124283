import { IPayableInvoice } from '@msdyn365-commerce-modules/invoice';
import { InvoicePaidStatus, OrderInvoice, SalesInvoice, SalesInvoiceLine, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

export interface IInvoicePaymentDetails {
    totalDiscount?: number;
    subtotal?: number;
    totalCharges?: number;
    totalTax?: number;
    totalAmount?: number;
    currencyCode?: string;
}

export interface IInvoiceProductLine extends SalesInvoiceLine {
    primaryImageUrl?: string;
}

/**
 * Represents invoice details data model.
 */
export class InvoiceDetailsModel implements IPayableInvoice, IInvoicePaymentDetails {
    public id: string;
    public invoiceDate?: Date;
    public customerAccountNumber?: string;
    public customerName?: string;
    public dueDate?: Date;
    public amountDue?: number;
    public status?: InvoicePaidStatus;

    public lines: IInvoiceProductLine[];

    public totalDiscount?: number;
    public subtotal?: number;
    public totalCharges?: number;
    public totalTax?: number;
    public totalAmount?: number;
    public currencyCode?: string;

    public constructor(invoice: OrderInvoice, invoiceDetails: SalesInvoice, products: SimpleProduct[]) {
        this.id = invoice.Id || '';
        this.invoiceDate = invoice.InvoiceDate ? new Date(invoice.InvoiceDate) : undefined;
        this.customerName = invoice.CustomerName;
        this.dueDate = invoice.InvoiceDueDate ? new Date(invoice.InvoiceDueDate) : undefined;
        this.amountDue = invoice.AmountBalance;
        this.status = invoice.InvoicePaidStatusValue;
        this.lines = (invoiceDetails.SalesInvoiceLine || []).map(line => {
            // tslint:disable-next-line:no-unnecessary-local-variable
            const productLine: IInvoiceProductLine = {
                ...line,
                primaryImageUrl: products.find(product => product.RecordId === line.RecordId)?.PrimaryImageUrl
            };
            return productLine;
        });

        this.totalDiscount = invoiceDetails.TotalDiscount;
        this.subtotal = invoiceDetails.InvoiceSubtotal;
        this.totalCharges = invoiceDetails.TotalCharges;
        this.totalTax = invoiceDetails.TotalTaxAmount;
        this.totalAmount = invoice.TotalAmount;
        this.currencyCode = invoice.CurrencyCode;
    }

    public get canBePaid(): boolean {
        return this.status !== InvoicePaidStatus.Paid;
    }
}