import classnames from 'classnames';
import React from 'react';

import { ArrayExtensions, ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { IComponent, IComponentProps, ICoreContext, msdyn365Commerce } from '@msdyn365-commerce/core';

import { IInvoicePaymentDetails } from '../helpers/invoice-data';

export interface IInvoiceDetailsPaymentDetailsData {
    invoicePaymentDetails: IInvoicePaymentDetails;
}

export interface IInvoiceDetailsPaymentDetailsResources {
    invoicePaymentDiscounts: string;
    invoicePaymentSubtotal: string;
    invoicePaymentCharges: string;
    invoicePaymentTax: string;
    invoicePaymentInvoiceTotal: string;
}

export interface IInvoiceDetailsPaymentDetailsProps extends IComponentProps<IInvoiceDetailsPaymentDetailsData> {
    context: ICoreContext;
    className: string;
    resources: IInvoiceDetailsPaymentDetailsResources;
}

export interface IInvoiceDetailsPaymentDetailsComponent extends IComponent<IInvoiceDetailsPaymentDetailsProps> {
}

interface IInvoicePaymentLineProps {
    label: string;
    value: number;
    className: string;
}

const InvoiceDetailsPaymentDetails: React.FC<IInvoiceDetailsPaymentDetailsProps> = (props: IInvoiceDetailsPaymentDetailsProps): JSX.Element | null => {
    const { className, data: { invoicePaymentDetails }, resources } = props;

    const lineClassName = `${className}__line`;

    const lines: IInvoicePaymentLineProps[] = [];
    if (!ObjectExtensions.isNullOrUndefined(invoicePaymentDetails.totalDiscount)) {
        lines.push({
            label: resources.invoicePaymentDiscounts,
            value: invoicePaymentDetails.totalDiscount,
            className: `${lineClassName}-total-discount`
        });
    }
    if (!ObjectExtensions.isNullOrUndefined(invoicePaymentDetails.subtotal)) {
        lines.push({
            label: resources.invoicePaymentSubtotal,
            value: invoicePaymentDetails.subtotal,
            className: `${lineClassName}-subtotal`
        });
    }
    if (!ObjectExtensions.isNullOrUndefined(invoicePaymentDetails.totalCharges)) {
        lines.push({
            label: resources.invoicePaymentCharges,
            value: invoicePaymentDetails.totalCharges,
            className: `${lineClassName}-total-charges`
        });
    }
    if (!ObjectExtensions.isNullOrUndefined(invoicePaymentDetails.totalTax)) {
        lines.push({
            label: resources.invoicePaymentTax,
            value: invoicePaymentDetails.totalTax,
            className: `${lineClassName}-total-tax`
        });
    }
    if (!ObjectExtensions.isNullOrUndefined(invoicePaymentDetails.totalAmount)) {
        lines.push({
            label: resources.invoicePaymentInvoiceTotal,
            value: invoicePaymentDetails.totalAmount,
            className: `${lineClassName}-total-amount`
        });
    }

    if (!ArrayExtensions.hasElements(lines)) {
        return null;
    }

    return (
        <div className={className}>
            {
                lines.map(line => {
                    return (
                        <div className={classnames(line.className, lineClassName)} key={line.label}>
                            <div className={`${lineClassName}__label`}>{line.label}</div>
                            <div className={`${lineClassName}__value`}>{props.context.cultureFormatter.formatCurrency(line.value, invoicePaymentDetails.currencyCode)}</div>
                        </div>
                    );
                })
            }
        </div>
    );
};

// @ts-ignore
export const InvoiceDetailsPaymentDetailsComponent: React.FunctionComponent<IInvoiceDetailsPaymentDetailsProps> = msdyn365Commerce.createComponent<IInvoiceDetailsPaymentDetailsComponent>(
    'InvoiceDetailsPaymentDetailsComponent',
    { component: InvoiceDetailsPaymentDetails }
);