import React from 'react';

import { getFallbackImageUrl, getProductPageUrlSync, ObjectExtensions, StringExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { IComponent, IComponentProps, ICoreContext, IImageSettings, Image, msdyn365Commerce } from '@msdyn365-commerce/core';
import { SalesInvoiceLine } from '@msdyn365-commerce/retail-proxy';
import { IInvoiceProductLine, InvoiceDetailsModel } from '../helpers/invoice-data';

export interface IInvoiceDetailsProductsData {
    invoiceDetails: InvoiceDetailsModel;
}

export interface IInvoiceDetailsProductsResources {
    invoiceProductColor: string;
    invoiceProductSize: string;
    invoiceProductStyle: string;
    invoiceQuantity: string;
}

export interface IInvoiceDetailsProductsProps extends IComponentProps<IInvoiceDetailsProductsData> {
    context: ICoreContext;
    className: string;
    resources: IInvoiceDetailsProductsResources;
}

export interface IInvoiceDetailsProductsComponent extends IComponent<IInvoiceDetailsProductsProps> {
}

const _renderProductDimensions = (className: string, resources: IInvoiceDetailsProductsResources, product: SalesInvoiceLine): JSX.Element => {
    return (
        <div className={className}>
            {StringExtensions.isNullOrWhitespace(product.ColorName) ? null : <div>{resources.invoiceProductColor.replace('{0}', product.ColorName!)}</div>}
            {StringExtensions.isNullOrWhitespace(product.SizeName) ? null : <div>{resources.invoiceProductSize.replace('{0}', product.SizeName!)}</div>}
            {StringExtensions.isNullOrWhitespace(product.StyleName) ? null : <div>{resources.invoiceProductStyle.replace('{0}', product.StyleName!)}</div>}
        </div>
    );
};

const _renderProductImage = (className: string, context: ICoreContext, product: IInvoiceProductLine, imageSettings?: IImageSettings): JSX.Element | null => {
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=315&h=315&m=6`, w: 0, h: 0 },
            lg: { q: `w=315&h=315&m=6`, w: 0, h: 0 },
            xl: { q: `w=315&h=315&m=6`, w: 0, h: 0 }
        },
        lazyload: true,
        cropFocalRegion: true
    };

    if (imageSettings) {
        imageSettings.cropFocalRegion = true;
    }

    const fallbackImage = getFallbackImageUrl(product.ItemId, context.actionContext.requestContext.apiSettings);
    return (
        <Image
            className={className}
            altText={product.ProductName}
            title={product.ProductName}
            src={product.primaryImageUrl || ''}
            fallBackSrc = {fallbackImage}
            gridSettings={context.request.gridSettings!}
            imageSettings={imageSettings || defaultImageSettings}
            loadFailureBehavior='empty'
        />
    );
};

const formatAmount = (context: ICoreContext, amount: number | undefined, currencyCode: string | undefined) => {
    if (amount === undefined) {
        return '';
    }
    return context.cultureFormatter.formatCurrency(amount, currencyCode);
};

const InvoiceDetailsProducts: React.FC<IInvoiceDetailsProductsProps> = (props: IInvoiceDetailsProductsProps): JSX.Element => {
    const { className, data: { invoiceDetails }, resources } = props;

    const productLineClass = `${className}__product-line`;
    const productLineInfoContainerClass = `${productLineClass}__product-info-container`;
    const productLineDetailsClass = `${productLineInfoContainerClass}__product-details`;
    const productLineSmallDetailsClass = `${productLineDetailsClass}__small-details`;

    return (
        <div className={className}>
            {
                invoiceDetails.lines.map(product => {
                    const quantity = product.Quantity || 1;

                    let productUrl = '';
                    if (!ObjectExtensions.isNullOrUndefined(product.ProductName) &&
                        !ObjectExtensions.isNullOrUndefined(product.RecordId)) {
                        productUrl = getProductPageUrlSync(product.ProductName, product.RecordId, props.context.actionContext);
                    }

                    return (
                        <div className={productLineClass} key={product.RecordId}>
                            <div className={productLineInfoContainerClass}>
                                {_renderProductImage(`${productLineInfoContainerClass}__product-image`, props.context, product)}
                                <div className={productLineDetailsClass}>
                                    <a href={productUrl} className={`${productLineDetailsClass}__name`}>
                                        {product.ProductName}
                                    </a>
                                    <div className={productLineSmallDetailsClass}>
                                        {_renderProductDimensions(`${productLineSmallDetailsClass}__dimensions`, resources, product)}
                                        <div className={`${productLineSmallDetailsClass}__price`}>{formatAmount(props.context, product.Price, invoiceDetails.currencyCode)}</div>
                                        <div className={`${productLineSmallDetailsClass}__quantity`}>{resources.invoiceQuantity.replace('{0}', quantity.toString())}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${productLineClass}__total-price`}>{formatAmount(props.context, product.NetAmount, invoiceDetails.currencyCode)}</div>
                        </div>
                    );
                })
            }
        </div>
    );
};

// @ts-ignore
export const InvoiceDetailsProductsComponent: React.FunctionComponent<IInvoiceDetailsProductsProps> = msdyn365Commerce.createComponent<IInvoiceDetailsProductsComponent>(
    'InvoiceDetailsProductsComponent',
    { component: InvoiceDetailsProducts }
);