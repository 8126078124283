import { getInvoiceDetailsPageUrlSync, StringExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Button } from '@msdyn365-commerce-modules/utilities';
import MsDyn365, { IComponent, IComponentProps, ICoreContext, IDateFormatOptions, msdyn365Commerce } from '@msdyn365-commerce/core';
import { Customer } from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import React, { useState } from 'react';
import { InvoiceModel } from '../../../helpers/invoice-data';
import { getInvoiceStatusText, IInvoiceStatusResources } from '../../../helpers/invoice-status';
import { IInvoiceErrorState } from '../../../invoices-list';
import { IPayInvoiceResources, PayInvoiceComponent } from '../../pay-invoice-component';
import { RequestInvoiceButton } from '../../request-invoice-button';

interface IInvoicesTableRowData {
    invoice: InvoiceModel;
    index: number;
    customer: Customer;
}

export interface IInvoicesTableRowResources extends IInvoiceStatusResources, IPayInvoiceResources {
    invoiceCurrentUser: string;
    invoiceViewDetailsButtonText: string;
    invoiceViewDetailsButtonAriaLabel: string;
    invoiceRequestButtonLabel: string;
}

export interface IInvoicesTableRowProps extends IComponentProps<IInvoicesTableRowData> {
    context: ICoreContext;
    className: string;
    isMobile: boolean;

    resources: IInvoicesTableRowResources;

    onChange(): void;
    onError?(errorState: IInvoiceErrorState): void;
    onRequestInvoice(invoice: InvoiceModel): void;
}

export interface IInvoicesTableRowComponent extends IComponent<IInvoicesTableRowProps> {

}

const formatAmount = (context: ICoreContext, amount: number | undefined, currencyCode: string | undefined) => {
    if (amount === undefined) {
        return '';
    }
    return context.cultureFormatter.formatCurrency(amount, currencyCode);
};

const formatDate = (context: ICoreContext, date: Date | undefined) => {
    if (date === undefined) {
        return '';
    }

    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric'};
    return context.cultureFormatter.formatDate(date, dateOptions);
};

const getDesktopRowCells = (props: IInvoicesTableRowProps, onSelectLine: () => void) => {
    const { data: { invoice, index, customer } } = props;

    const isCurrentUser = !StringExtensions.isNullOrWhitespace(customer.AccountNumber) && customer.AccountNumber === invoice.customerAccountNumber;
    const payInvoiceClassName = `${props.className}__pay-invoice`;

    const onRequestSpecificInvoice = () => {
        props.onRequestInvoice(props.data.invoice);
    };

    const linkToInvoiceDetails = getInvoiceDetailsPageUrlSync(props.data.invoice.id, props.context.actionContext);

    return (
        <>
            <td>
                <label className={classnames(`${props.className}__checkbox-container`, 'checkbox-container')}>
                    <input
                        className={classnames(`${props.className}__checkbox-input`, 'checkbox-input')}
                        type='checkbox'
                        checked={invoice.isSelected}
                        aria-checked={invoice.isSelected}
                        onChange={onSelectLine}
                    />
                    <span className={classnames(`${props.className}__checkmark`, 'checkmark')}/>
                </label>
            </td>
            <td><a className={`${props.className}__open-invoice`} href={linkToInvoiceDetails}>{invoice.id}</a></td>
            <td><b>{formatDate(props.context, invoice.invoiceDate)}</b></td>
            { customer.IsB2bAdmin ? <td>{invoice.customerName}{ isCurrentUser ? <b>{props.resources.invoiceCurrentUser}</b> : null}</td> : null }
            <td>{formatAmount(props.context, invoice.amount, invoice.currencyCode)}</td>
            <td><b>{formatDate(props.context, invoice.dueDate)}</b></td>
            <td>{formatAmount(props.context, invoice.amountDue, invoice.currencyCode)}</td>
            <td><b>{getInvoiceStatusText(props.resources, invoice.status)}</b></td>
            <td>
                <PayInvoiceComponent
                    id={`${payInvoiceClassName}-${index}`}
                    className={payInvoiceClassName}
                    typeName={props.typeName}
                    context={props.context}
                    resources={props.resources}
                    data={{invoice: props.data.invoice}}
                    onError={props.onError}
                />
                <RequestInvoiceButton
                    className={props.className}
                    requestInvoiceButtonLabel={props.resources.invoiceRequestButtonLabel}
                    onClicked={onRequestSpecificInvoice}
                />
            </td>
        </>
    );
};

let setShowPreviousActions: React.Dispatch<React.SetStateAction<boolean>> | undefined;

const extraActionsPopup = (props: IInvoicesTableRowProps): JSX.Element | null => {
    const { resources: { invoiceViewDetailsButtonAriaLabel, invoiceViewDetailsButtonText }, data: { index } } = props;

    const [showActions, setShowActions ] = useState(false);

    const linkToInvoiceDetails = getInvoiceDetailsPageUrlSync(props.data.invoice.id, props.context.actionContext);

    const onClickViewDetails = () => {
        if (MsDyn365.isBrowser) {
            window.location.href = linkToInvoiceDetails;
        }
        return;
    };

    const toggle = (event: React.MouseEvent) => {
        const shouldShowActions = !showActions;
        if (shouldShowActions) {
            if (setShowPreviousActions) {
                setShowPreviousActions(false);
            }
            setShowActions(shouldShowActions);
            setShowPreviousActions = setShowActions;
        } else {
            setShowPreviousActions = undefined;
            setShowActions(shouldShowActions);
        }
    };

    const onRequestSpecificInvoice = () => {
        props.onRequestInvoice(props.data.invoice);
    };

    const className = `${props.className}__extra-actions-cell`;
    const actionsContainerClassName = `${className}__actions-container`;
    const payInvoiceClassName = `${actionsContainerClassName}__pay-invoice`;

    return (
        <div className={className}>
            {<Button className={`${className}__toggle`} onClick={toggle}/>}
            {
            showActions &&
                <div className={actionsContainerClassName}>
                    <Button
                        className={`${actionsContainerClassName}__view-details`}
                        onClick={onClickViewDetails}
                        aria-label={invoiceViewDetailsButtonAriaLabel}
                    >
                        {invoiceViewDetailsButtonText}
                    </Button>
                    <PayInvoiceComponent
                        id={`${payInvoiceClassName}-${index}`}
                        className={payInvoiceClassName}
                        typeName={props.typeName}
                        context={props.context}
                        resources={props.resources}
                        data={{invoice: props.data.invoice}}
                        onError={props.onError}
                    />
                    <RequestInvoiceButton
                        className={actionsContainerClassName}
                        requestInvoiceButtonLabel={props.resources.invoiceRequestButtonLabel}
                        onClicked={onRequestSpecificInvoice}
                    />
                </div>
             }
        </div>
    );
};

const getMobileRowCells = (props: IInvoicesTableRowProps, onSelectLine: () => void) => {
    const { data: { invoice } } = props;

    const linkToInvoiceDetails = getInvoiceDetailsPageUrlSync(props.data.invoice.id, props.context.actionContext);

    // tslint:disable:react-a11y-anchors
    return (
        <>
            <td>
                <label className={classnames(`${props.className}__checkbox-container`, 'checkbox-container')}>
                    <input
                        className={classnames(`${props.className}__checkbox-input`, 'checkbox-input')}
                        type='checkbox'
                        checked={invoice.isSelected}
                        aria-checked={invoice.isSelected}
                        onChange={onSelectLine}
                    />
                    <span className={classnames(`${props.className}__checkmark`, 'checkmark')}/>
                </label>
            </td>
            <td>
                <a className={`${props.className}__open-invoice`} href={linkToInvoiceDetails}>{invoice.id}</a>
                <br/>
                <b>{invoice.customerName}</b>
            </td>
            <td>
                <b>{formatDate(props.context, invoice.dueDate)}</b>
                <br/>
                <span>{getInvoiceStatusText(props.resources, invoice.status)}</span>
            </td>
            <td>
                {extraActionsPopup(props)}
            </td>
        </>
    );
    // tslint:enable:react-a11y-anchors
};

const InvoicesTableRow: React.FC<IInvoicesTableRowProps> = (props: IInvoicesTableRowProps): JSX.Element => {
    const { data: { invoice } } = props;

    const onSelectLine = () => {
        invoice.isSelected = !invoice.isSelected;
        props.onChange();
    };

    let rowCells: JSX.Element;
    if (props.isMobile) {
        rowCells = getMobileRowCells(props, onSelectLine);
    } else {
        rowCells = getDesktopRowCells(props, onSelectLine);
    }

    return (
        <tr className={props.className}>
            {rowCells}
        </tr>
    );
};

// @ts-ignore
export const InvoicesTableRowComponent: React.FunctionComponent<IInvoicesTableRowProps> = msdyn365Commerce.createComponent<IInvoicesTableRowComponent>(
    'InvoicesTableRowComponent',
    { component: InvoicesTableRow }
);