import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { IComponent, IComponentProps, ICoreContext, msdyn365Commerce } from '@msdyn365-commerce/core';
import { Customer } from '@msdyn365-commerce/retail-proxy';
import React, { useState } from 'react';
import { InvoiceModel } from '../../helpers/invoice-data';
import { IInvoiceErrorState } from '../../invoices-list';
import { IInvoicesTableHeaderResources, InvoicesTableHeaderComponent } from './components/invoices-table-header-component';
import { IInvoicesTableRowResources, InvoicesTableRowComponent } from './components/invoices-table-row-component';

interface IInvoicesTableData {
    invoices: InvoiceModel[];
    customer: Customer;
}

// tslint:disable-next-line:no-empty-interface
export interface IInvoicesTableResources extends IInvoicesTableHeaderResources, IInvoicesTableRowResources {
}

export interface IInvoicesTableProps extends IComponentProps<IInvoicesTableData> {
    context: ICoreContext;
    className: string;

    resources: IInvoicesTableResources;

    onChange(): void;
    onError?(errorState: IInvoiceErrorState): void;
    onRequestInvoice(invoice: InvoiceModel): void;
}

export interface IInvoicesTableComponent extends IComponent<IInvoicesTableProps> {
}

type GridSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const getViewport = (context: ICoreContext): GridSize => {
    // Always render in mobile viewport on a mobile device
    if (context.request && context.request.device && context.request.device.Type === 'Mobile') {
        return 'xs';
    }

    if (window?.innerWidth) {
        const gridSettings = context.request.gridSettings;
        if (gridSettings) {
            if (gridSettings.xs && window.innerWidth <= gridSettings.xs.w) {
                return 'xs';
            } else if (gridSettings.sm && window.innerWidth <= gridSettings.sm.w) {
                return 'sm';
            } else if (gridSettings.md && window.innerWidth <= gridSettings.md.w) {
                return 'md';
            } else if (gridSettings.lg && window.innerWidth <= gridSettings.lg.w) {
                return 'lg';
            } else {
                return 'xl';
            }
        }
    }

    return 'xs';
};

const isMobileView = (context: ICoreContext) => {
    const viewport = getViewport(context);
    return viewport === 'xs' || viewport === 'sm';
};

const InvoicesTable: React.FC<IInvoicesTableProps> = (props: IInvoicesTableProps): JSX.Element => {
    const [isMobile, setIsMobile] = useState(isMobileView(props.context));

    const updateViewport = () => {
        setIsMobile(isMobileView(props.context));
    };

    if (typeof window !== undefined && window.addEventListener) {
        window.addEventListener('resize', updateViewport);
    }
    const rowClassName = `${props.className}__row`;
    const headerClassName = `${props.className}__header`;

    const onSelectAll = (isSelected: boolean) => {
        props.data.invoices.forEach((invoice) => {
            invoice.isSelected = isSelected;
        });
        props.onChange();
    };

    return (
        <table className={props.className}>
            <InvoicesTableHeaderComponent
                context={props.context}
                typeName={props.typeName}
                id={headerClassName}
                className={headerClassName}
                isSelectedAll={ArrayExtensions.all(props.data.invoices, (invoice: InvoiceModel) => invoice.isSelected)}
                isMobile={isMobile}
                isAdmin={props.data.customer.IsB2bAdmin || false}
                onSelectAll={onSelectAll}
                resources={props.resources}
                data={{}}
            />
            {
                props.data.invoices.map((invoice, index) => {
                    return (
                        <InvoicesTableRowComponent
                            context={props.context}
                            typeName={props.typeName}
                            id={`${rowClassName}-${index}`}
                            key={index}
                            className={rowClassName}
                            resources={props.resources}
                            data={{invoice, index, customer: props.data.customer}}
                            onChange={props.onChange}
                            isMobile={isMobile}
                            onError={props.onError}
                            onRequestInvoice={props.onRequestInvoice}
                        />
                    );
                })
            }
        </table>
    );
};

// @ts-ignore
export const InvoicesTableComponent: React.FunctionComponent<IInvoicesTableProps> = msdyn365Commerce.createComponent<IInvoicesTableComponent>(
    'InvoicesTableComponent',
    { component: InvoicesTable }
);